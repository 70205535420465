.card {
    position: relative;
    padding: 20px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    /* box-shadow: 0px 18px 53px 0px rgba(0, 0, 0, 0.3); */
    color: #fff;
    background-color: #000;
    text-align: center;
}

.memeCards__cardContainer {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

.title {
    margin-bottom: 15px;
}

.swipe {
    position: absolute;
    border: solid lightblue 1px;
    border-radius: 20px;
}

.image {
    height: auto;
    width: auto;
    max-width: 85vw;
    max-height: 60vh;
}
