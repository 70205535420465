.list_image {
    /* width: 40vw; */
    max-width: 20vw;
    min-width: 20vw;
    max-height: 200px;
    padding-right: 3vw;
    object-fit: scale-down;
}

.list_text {
    overflow-x: hidden;
    max-width: 40vw;
}

.modal {
    margin: auto;
    position: "absolute";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: fit-content;
    height: fit-content;

    background-color: rgba(255, 255, 255, 0.5);
    /* border: 2px solid #000; */
    outline: 0 !important;
    box-shadow: darkgray;
}
.modal_image {
    max-width: 80vw;
    max-height: 80vh;
    object-fit: scale-down;
}
.pic_header {
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
}
.caption_edit {
    background-color: white;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.caption_header {
    color: black;
    padding-bottom: 0.5em;
}
.caption_textfield {
    width: 80vw;
    max-width: 400px;
}
